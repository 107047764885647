@import "../util/style.scss";

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: var(--new100vh);
  width: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0);
  transition: all 0.3s;
  overflow-y: visible;
}

.frame-popup {
  position: absolute;
  width: 100%;
  // display: flex;
  // justify-content: center;
  bottom: -50px;
  opacity: 1;
  transition: all 0.3s;
  background-color: $col-w;
  border-radius: 1rem 1rem 0 0;
  transition-timing-function: cubic-bezier(0.19, 0.69, 0.64, 1.1);
  box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.05);
}

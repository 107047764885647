@import "../util/style.scss";

.wrapper {
  cursor: pointer;
  background-color: $col-verylight-nongrey;
  width: 100%;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  line-height: 20px;
  font-family: "Noto Sans";
  transition: all 0.3s;
}

.inactive {
  font-family: "Noto Sans KR";
  font-weight: 600;
  color: $col-grey;
}

.active {
  font-family: "Noto Sans KR";
  font-weight: 600;
  background-color: $col-dark;
  color: $col-verylight-nongrey;
}

.color {
  &-default {
    background-color: $col-w;
    color: $col-k;
  }
  &-transparent {
    color: $col-grey;
    box-shadow: none;
    font-weight: $font-sr;
    &:hover {
      background-color: $col-verylight;
      box-shadow: none;
      // @include shd-2;
    }
  }
  &-primary {
    background-color: $col-1;
    color: $col-w;
    // border: 1px solid $col-g9;
    // @include shd-1;
    &:hover {
      background-color: $col-1-hover;
      // @include shd-2;
    }
  }
  &-excel {
    background-color: #169937;
    color: $col-w;
    font-weight: $font-b;
    &:hover {
      opacity: 0.9;
      // @include shd-2;
    }
  }
  &-cad {
    background-color: #c5383f;
    color: $col-w;
    font-weight: $font-b;
    &:hover {
      opacity: 0.9;
      // @include shd-2;
    }
  }
}

.clickable {
  &.transparent {
    cursor: pointer;
    &:hover {
      background-color: $col-g95;
      // @include shd-1;
    }
  }
  &.solid {
    cursor: pointer;
    &:hover {
      background-color: $col-g95;
      // @include shd-2;
    }
  }
}

.full {
  width: 100%;
  height: 100%;
}

.icon {
  fill: $col-w;
  height: 1.1rem;
}

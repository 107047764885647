@import "../util/style.scss";

.wrapper {
  padding: 12px 8px 10px 8px;
  cursor: pointer;
  pointer-events: auto;
}

.frame-bar {
  width: 100%;
  height: 2px;
  background-color: $col-1;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  &.focused {
    visibility: visible;
    opacity: 1;
  }
}

.text {
  white-space: nowrap;
  color: $col-k;
  transition: all 0.3s;
  &.focused {
    color: $col-1;
    // font-weight: $font-b;
  }
}

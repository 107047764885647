@import "../util/style.scss";

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: var(--new100vh);
  width: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0);
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.frame-popup {
  width: calc(100% - 4rem);
  padding: 1rem;
  // display: flex;
  // justify-content: center;
  bottom: -50px;
  opacity: 1;
  transition: all 0.3s;
  background-color: $col-w;
  border-radius: 2rem;
  transition-timing-function: cubic-bezier(0.19, 0.69, 0.64, 1.2);
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.05);
}

.section-title {
  padding: 8px 12px 0px 12px;
  color: $col-lightgrey;
  font-size: 1.125rem;
  line-height: 1.125rem;
  font-weight: 500;
  width: calc(100% - 1.5rem);
}
